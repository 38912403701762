import React from "react";

import logoDark from "../assets/logo-dark.svg";
import logoLight from "../assets/logo-light.svg";

const Logo: React.FC<{
  className?: string | undefined;
  light?: boolean | undefined;
}> = ({ className, light }) => {
  return light ? (
    <img alt="" src={logoDark} className={className} />
  ) : (
    <img alt="" src={logoLight} className={className} />
  );
};

export default Logo;
