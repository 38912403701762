import React from "react";
import type { HeadFC } from "gatsby";
import Header from "../components/header";
import PrimaryFeatures from "../components/primary-features";
import CallToAction from "../components/call-to-action";
import Hero from "../components/hero";
import Footer from "../components/footer";

const IndexPage: React.FC = () => {
  return (
    <>
      <Header />
      <Hero />
      <PrimaryFeatures />
      <CallToAction />
      <Footer />
    </>
  );
};

export default IndexPage;
export const Head: HeadFC = () => <title>Notyphi</title>;
