import React from "react";

import backgroundImage from "../assets/background-features.jpg";

import followImage from "../assets/follow_wallet.png";
import tagsImage from "../assets/tags_wallet.png";
import notificationImage from "../assets/noty_wallet.png";
import onoffImage from "../assets/onoff.png";
import Container from "./container";
import { Tab } from "@headlessui/react";
import clsx from "clsx";

const features = [
  {
    title: "Choose who you follow",
    description:
      "Notyphi allows you to choose which organisations you can follow",
    image: followImage,
  },
  {
    title: "Choose your interests",
    description:
      "All of your receipts organized into one place, as long as you don't mind typing in the data by hand.",
    image: tagsImage,
  },
  {
    title: "Intelligent notifications",
    description:
      "Notyphi will intelligently push notifications to you wallet based on the information that you give it",
    image: notificationImage,
  },
  {
    title: "Always up to you",
    description:
      "Don't want any notifications at the moment - no worries. Pause your notifications for the time being or switch them off entirely. You always have full control",
    image: onoffImage,
  },
];

type FeatureProperty = {
  title: string;
  description: string;
  image: string;
};

const Feature: React.FC<{
  className?: string | undefined;
  isActive?: boolean | undefined;
  feature: FeatureProperty;
}> = ({ className, isActive, feature }) => {
  return (
    <div
      className={clsx(className, !isActive && "opacity-75 hover:opacity-100")}
    >
      <p className="mt-4 text-3xl font-light text-white">{feature.title}</p>
      <p className="text-l mt-4 font-light text-white">{feature.description}</p>
    </div>
  );
};

const FeaturesMobile: React.FC = () => {
  return (
    <div className="bg-sherpa-600 m:hidden -mx-4 mt-20 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden">
      {features.map((feature) => (
        <div key={feature.title}>
          <Feature feature={feature} className="mx-auto max-w-2xl" isActive />
          <div className="relative mt-10 pb-10">
            <div className="bg-sherpa-600 absolute -inset-x-4 bottom-0 top-8 sm:-inset-x-6" />
            <div className="relative mx-auto w-[360px] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
              <img className="w-full" src={feature.image} alt="" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const FeaturesDesktop: React.FC = () => {
  return (
    <Tab.Group
      as="div"
      className="hidden grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:mt-16 lg:grid lg:grid-cols-12 lg:pt-0"
      // vertical={tabOrientation === "vertical"}
    >
      {({ selectedIndex }) => (
        <>
          <div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
            <Tab.List className="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal">
              {features.map((feature, featureIndex) => (
                <div
                  key={feature.title}
                  className={clsx(
                    "group relative rounded-lg py-1 px-4 lg:p-6",
                    selectedIndex === featureIndex
                      ? "bg-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10"
                      : "hover:bg-white/10 lg:hover:bg-white/5"
                  )}
                >
                  <h3>
                    <Tab
                      className={clsx(
                        "font-display text-lg [&:not(:focus-visible)]:focus:outline-none",
                        selectedIndex === featureIndex
                          ? "text-sherpa-600 lg:text-white"
                          : "text-blue-100 hover:text-white lg:text-white"
                      )}
                    >
                      <span className="absolute inset-0 rounded-lg" />
                      {feature.title}
                    </Tab>
                  </h3>
                  <p
                    className={clsx(
                      "mt-2 hidden text-sm lg:block",
                      selectedIndex === featureIndex
                        ? "text-white"
                        : "text-blue-100 group-hover:text-white"
                    )}
                  >
                    {feature.description}
                  </p>
                </div>
              ))}
            </Tab.List>
          </div>
          <Tab.Panels className="lg:col-span-7">
            {features.map((feature) => (
              <Tab.Panel key={feature.title} unmount={false}>
                <div className="relative sm:px-6 lg:hidden">
                  <div className="absolute -inset-x-4 top-[-6.5rem] bottom-[-4.25rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl" />
                  <p className="relative mx-auto max-w-2xl text-base text-white sm:text-center">
                    {feature.description}
                  </p>
                </div>
                <div>
                  <img
                    src={feature.image}
                    alt=""
                    className="p-5px mx-auto rounded-lg"
                  />
                </div>
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </>
      )}
    </Tab.Group>
  );
};

const PrimaryFeatures: React.FC = () => {
  return (
    <section
      id="for-users"
      aria-label="Features for running your books"
      className="relative overflow-hidden pt-20 pb-28 sm:py-32"
      style={{ backgroundColor: "#006C85" }}
    >
      <img
        className="absolute top-1/2 left-1/2 max-w-none translate-x-[-44%] translate-y-[-42%] max-lg:hidden"
        src={backgroundImage}
        alt=""
        height={1636}
      />
      <Container className="relative">
        <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl md:text-5xl">
            Stay in control
          </h2>
          <p className="mt-6 text-lg tracking-tight text-blue-100">
            Don&apos;t let todays hectic lifestyles get you down. Choose when
            and who can send you notifications
          </p>
        </div>
        <FeaturesMobile />
        <FeaturesDesktop />
      </Container>
    </section>
  );
};

export default PrimaryFeatures;
