import clsx from "clsx";
import React from "react";
import { Link } from "gatsby";

const baseStyles = {
  solid:
    "group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2",
  outline:
    "group inline-flex ring-1 items-center justify-center rounded-full py-2 px-4 text-sm focus:outline-none",
};

const variantStyles = {
  solid: {
    slate:
      "bg-slate-900 text-white hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-slate-900 ",
    green:
      "bg-persian text-white hover:text-slate-100 hover:bg-persian-800 active:bg-persian-800 focus-visible:outline-persian-800",
    white:
      "bg-white text-slate-900 hover:bg-blue-50 active:bg-blue-200 active:text-slate-600 focus-visible:outline-white disabled:text-slate-400 disabled:bg-slate-200 disabled:cursor-not-allowed",
  },
  outline: {
    slate:
      "ring-slate-200 text-slate-700 hover:text-slate-900 hover:ring-slate-300 active:bg-slate-100 active:text-slate-600 focus-visible:outline-blue-600 focus-visible:ring-slate-300 ",
    green: "",
    white:
      "ring-slate-700 text-white hover:ring-slate-500 active:ring-slate-700 active:text-slate-400 focus-visible:outline-white",
  },
};

const Button: React.FC<{
  disabled?: boolean | undefined;
  type?: "button" | "submit" | "reset" | undefined;
  variant?: "solid" | "outline" | undefined;
  color?: "slate" | "green" | "white" | undefined;
  href?: string | undefined;
  className?: string | undefined;
  children?: React.ReactNode | undefined;
}> = ({ type, variant, color, className, href, children, disabled }) => {
  const buttonStyle = clsx(
    baseStyles[variant ?? "solid"],
    variantStyles[variant ?? "solid"][color ?? "slate"],
    className
  );

  return href !== undefined ? (
    <Link to={href} className={buttonStyle}>
      {children}
    </Link>
  ) : (
    <button className={buttonStyle} type={type} disabled={disabled}>
      {children}
    </button>
  );
};

export default Button;
