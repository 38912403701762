import { API_BASE_URL } from "../../config";

export interface Signup {
  email: string;
}

export interface SignupResponse {
  message: string;
  id: string;
}

async function apiSignUp(email: Signup): Promise<SignupResponse> {
  const resp = await fetch(`${API_BASE_URL}/v1/registration`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(email),
  });

  if (!resp.ok) {
    return { message: "Error with signup", id: "" };
  }

  return await resp.json();
}

export default apiSignUp;
