import React, { Fragment, useEffect, useState } from "react";

import Container from "./container";
import Button from "./button";
import apiSignUp from "../app/api";
import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  XMarkIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";

const Notification: React.FC<{
  type?: "success" | "failure";
  title?: string | undefined;
  subtitle?: string | undefined;
}> = ({ type, title, subtitle }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if ((title ?? "") === "" && (subtitle ?? "") === "") {
      return;
    }
    setShow(true);
  }, []);

  useEffect(() => {
    if (!show) {
      return;
    }
    if ((title ?? "") === "" && (subtitle ?? "") === "") {
      return;
    }

    const timeout = setTimeout(() => setShow(false), 2500);
    return () => clearTimeout(timeout);
  }, [show, title, subtitle]);

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {type === "success" && (
                      <CheckCircleIcon
                        className="h-6 w-6 text-green-400"
                        aria-hidden="true"
                      />
                    )}
                    {type === "failure" && (
                      <ExclamationCircleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    {title !== undefined && (
                      <p className="text-sm font-medium text-gray-900">
                        {title}
                      </p>
                    )}
                    {subtitle !== undefined && (
                      <p className="mt-1 text-sm text-gray-500">{subtitle}</p>
                    )}
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

const CallToAction: React.FC = () => {
  const isValidEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const [email, setEmail] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState<boolean | undefined>();

  const handleJoinWaitList = () => {
    if (email === "") {
      return;
    }

    apiSignUp({ email: email })
      .then(() => setSubmitSuccess(true))
      .catch((error) => {
        console.error(error);
        setSubmitSuccess(false);
      });
  };

  return (
    <>
      <section
        id="get-started-today"
        className="relative overflow-hidden py-32"
      >
        <Container className="relative">
          <form
            className="mx-auto max-w-lg text-center"
            onSubmit={(event) => {
              event.preventDefault();
              handleJoinWaitList();
            }}
          >
            <h2 className="font-display text-3xl font-light tracking-tight sm:text-4xl">
              Get started today
            </h2>
            <p className="mt-4 text-lg font-light tracking-tight">
              If you are interested in joining the waiting list for our
              marketing portal please fill out the email address below and we
              will get back to you shortly
            </p>
            <div className="mt-10">
              <input
                className="m-0 block w-full rounded-lg
                                      border
                                      border-solid
                                      border-gray-300
                                      bg-white
                                      bg-clip-padding px-4
                                      py-2 text-2xl text-gray-700
                                      shadow-sm
                                      transition
                                      ease-in-out
                                      focus:border-blue-600
                                      focus:bg-white focus:text-gray-700 focus:outline-none sm:text-sm"
                type="email"
                placeholder="you@example.com"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                  setSubmitSuccess(undefined);
                }}
              />
            </div>
            <Button
              color="slate"
              className="mt-10"
              type="submit"
              disabled={email !== "" && !isValidEmail.test(email)}
            >
              Join Wait list
            </Button>
          </form>
        </Container>
      </section>
      {submitSuccess === true && (
        <Notification
          type="success"
          title="Added to waitlist"
          subtitle="You have been successfully added to the waitlist"
        />
      )}
      {submitSuccess === false && (
        <Notification
          type="failure"
          title="Failed to join waitlist"
          subtitle="Something went wrong and we could not add you to the waitlist"
        />
      )}
    </>
  );
};

export default CallToAction;
