import React from "react";
import Container from "./container";
import Button from "./button";

const Hero: React.FC = () => {
  return (
    <Container className="pt-20 pb-16 text-center lg:pt-32">
      <h1 className="font-display mx-auto max-w-4xl text-5xl font-medium tracking-tight text-slate-900 sm:text-7xl">
        Notifications, personalised to you, always under your control
      </h1>
      <p className="mx-auto mt-6 max-w-2xl text-lg tracking-tight text-slate-700">
        We are constantly bombarded with notifications competing for our
        attention. Change the paradigm and control how and when you get notified
      </p>
      <div className="mt-10 mb-28 flex justify-center gap-x-6">
        <Button href="https://chrome.google.com/webstore/detail/fetch-wallet/ellkdbaphhldpeajbepobaecooaoafpg">
          Download Fetch Wallet
        </Button>
        {/*<Button href="#for-marketers" variant="outline">*/}
        {/*  <span className="ml-3">Manage your tribe</span>*/}
        {/*</Button>*/}
      </div>
    </Container>
  );
};

export default Hero;
