import React from "react";
import Container from "./container";
import Logo from "./logo";
import NavLink from "./nav-link";

const Footer: React.FC = () => {
  return (
    <footer className="bg-slate-50">
      <Container>
        <div className="py-16">
          <Logo className="mx-auto h-10 w-auto" />
          <nav className="mt-10 text-sm" aria-label="quick links">
            <div className="-my-1 flex justify-center gap-x-6">
              <NavLink href="#for-users">For Users</NavLink>
              {/*<NavLink href="#for-marketers">For Marketers</NavLink>*/}
              <NavLink href="#get-started-today">Get Started</NavLink>
              {/*<NavLink href="#faq">FAQs</NavLink>*/}
            </div>
          </nav>
        </div>
        <div className="flex flex-col items-center border-t border-slate-400/10 py-10 sm:flex-row-reverse sm:justify-between">
          <p className="mt-6 text-sm text-slate-500 sm:mt-0">
            Copyright &copy; {new Date().getFullYear()} Fetch.ai. All rights
            reserved.
          </p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
